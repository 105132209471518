import { CalculatorQuestionSession } from "@prisma/client";
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CalculatorSession,
  DataEntry,
  DataEntryWithRelation,
  PostAnswer,
} from "../../types/Co2CalculatorAnswerTypes";
import { TSelectCurrentQuestion } from "../util/prismaHelper";
import { RootState } from "./store";

export type CalculatorStoreState = {
  data: CalculatorSession.Data;
  answers: CalculatorSession.Answers;
  summary: CalculatorSession.Summary;
  currentQuestion: TSelectCurrentQuestion | null;
  session:
    | Omit<CalculatorQuestionSession, "data" | "answers" | "summary">
    | null
    | undefined;
  nextQuestionId: string | null;
  previousQuestionId: string | null;
};

const asyncUpdateAnswer = createAsyncThunk(
  "local/updateAnswer",
  async (data: Parameters<typeof _postAnswer>[0], thunkAPI) => {
    try {
      const state = (thunkAPI.getState() as RootState).calculator;
      let value = data.value;
      if (!value) {
        value = {
          value: {},
          _meta: {},
        };
      }
      const questionId = data.qId;
      // console.log("UpdateAnswer:", value, state.answers[questionId]);
      if (typeof value.value === "string" || typeof value.value === "number") {
      } else {
        value.value = Object.assign(
          {},
          state.answers[questionId]?.value,
          value.value
        );
        value._meta = Object.assign(
          {},
          state.answers[questionId]?._meta,
          value._meta
        );
      }
      const data1 = { ...data, value: value };
      // console.log("updateAnswer", data, data1, state.answers[questionId], value);
      const resp = await _postAnswer(data1);
      return { data: data1, resp };
    } catch (e) {
      console.error(e);
    }
  }
);

const asyncReplaceAnswer = createAsyncThunk(
  "local/replaceAnswer",
  async (data: Parameters<typeof _postAnswer>[0]) => {
    const resp = await _postAnswer(data);
    console.log("replaceAnswer", data, resp);
    return { data, resp };
  }
);

const initialState: CalculatorStoreState = {
  currentQuestion: null,
  session: undefined,
  data: {},
  answers: {},
  summary: {},
  nextQuestionId: null,
  previousQuestionId: null,
};

const lSlice = createSlice({
  name: "local",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(asyncUpdateAnswer.fulfilled, asyncReplaceAnswer.fulfilled),
      (state, action) => {
        if (!action.payload) return;

        if (action.payload.resp) {
          state.nextQuestionId = action.payload.resp.nextQuestionId;
        }
        if (state.session) {
          state.session.skippedQuestionIds =
            action.payload.resp.skippedQuestionIds;

          for (let qId of action.payload.resp.skippedQuestionIds) {
            delete state.answers[qId];
            delete state.data[qId];
          }
        }

        const value = action.payload.data.value;
        const questionId = action.payload.data.qId;
        state.answers[questionId] = value;

        updateSumSummary(state);
      }
    );
    builder.addMatcher(
      isAnyOf(asyncUpdateAnswer.rejected, asyncReplaceAnswer.rejected),
      (state, action) => {
        console.log("async answer has be rejected");
        console.log("Action", action.error);
      }
    );
  },
  reducers: {
    setNQId: (state, action: PayloadAction<string | null>) => {
      state.nextQuestionId = action.payload;
    },
    setPQId: (state, action: PayloadAction<string | null>) => {
      state.previousQuestionId = action.payload;
    },
    setQuestion: (
      state,
      { payload }: PayloadAction<TSelectCurrentQuestion>
    ) => {
      state.currentQuestion = payload;
    },
    setCurrentQuestionId: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (!state.session) return;
      state.session.currentQuestionId = payload;
    },
    updateData: (
      state,
      {
        payload: { multi, ...value },
      }: PayloadAction<
        {
          questionId: string;
          multi?: string;
        } & DataEntry
      >
    ) => {
      if (multi != null && multi != "") {
        if (!state.data[value.questionId]) {
          state.data[value.questionId] = {};
        }
        (state.data[value.questionId] as Record<string, DataEntry>)[multi] =
          value;
      } else {
        state.data[value.questionId] = {
          ...value,
        };
      }

      updateSumSummary(state);
    },
    removeData: (
      state,
      {
        payload,
      }: PayloadAction<{
        questionId: string;
        answerId: string;
      }>
    ) => {
      const qData = state.data[payload.questionId];
      if (payload.answerId in qData) {
        const _qd = qData as Record<string, DataEntry>;
        delete _qd[payload.answerId];
      } else if (isDataEntry(qData)) {
        delete state.data[payload.questionId];
      }
      updateSumSummary(state);
    },
    nextQuestion: (state) => {
      if (!state.session || !state.nextQuestionId) return;
      state.session.currentQuestionNumber += 1;
      state.session.currentQuestionId = state.nextQuestionId;
    },
    previousQuestion: (state) => {
      if (!state.session) return;
      state.session.currentQuestionNumber -= 1;
      state.session.currentQuestionId = state.previousQuestionId;
    },
  },
});

export const calculatorReducer = lSlice.reducer;
export const calculatorActions = {
  ...lSlice.actions,
  asyncReplaceAnswer: asyncReplaceAnswer,
  asyncUpdateAnswer: asyncUpdateAnswer,
};

async function _postAnswer(data: {
  qId: string;
  qaId: string;
  value: CalculatorStoreState["answers"][string];
}): Promise<PostAnswer> {
  // console.log("postAnswer", data);
  const resp = await fetch("/api/co2-calculator/a", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return await resp.json();
}

function isDataEntry(obj: any): obj is DataEntry {
  return typeof obj === "object" && "questionId" in obj && "data" in obj;
}

function isDataEntryWithRelation(
  obj: any
): obj is DataEntryWithRelation & { factor?: number } {
  return (
    isDataEntry(obj) &&
    "_usesQuestionIdAndAnswerId" in obj &&
    Array.isArray(obj._usesQuestionIdAndAnswerId) &&
    obj._usesQuestionIdAndAnswerId.length > 0
  );
}

function updateSumSummary(state: CalculatorStoreState) {
  const summary: CalculatorStoreState["summary"] = {};
  const reduceFunction = (
    a: number,
    dataEntryOrRecord: CalculatorStoreState["data"][string]
  ): number => {
    let v: number;
    if (isDataEntryWithRelation(dataEntryOrRecord)) {
      v = 0;
      for (let qa of dataEntryOrRecord._usesQuestionIdAndAnswerId) {
        if (qa.questionId in state.data) {
          const d = state.data[qa.questionId] as DataEntry;
          if ("value" in d) {
            v =
              (dataEntryOrRecord.factor ?? 1) *
              (state.data[qa.questionId] as { value: number }).value;
          } else if ("factor" in d) {
            v += v * d.factor;
          }
        } else if (qa.questionId in state.answers) {
          const answer = state.answers[qa.questionId].value;
          let value: number = 0;
          switch (typeof answer) {
            case "string":
              value = 0;
              break;
            case "number":
              value = answer;
              break;
            case "object":
              for (let _k in answer) {
                const _v = answer[_k];
                if (typeof _v === "number") {
                  value += _v;
                  break;
                }
              }
              break;
            default:
              value = 0;
          }
          v +=
            dataEntryOrRecord.data.factor *
            ("value" in dataEntryOrRecord.data
              ? dataEntryOrRecord.data.value
              : 1) *
            (qa.factor ?? 1) *
            value;
        }
      }
      if (!(dataEntryOrRecord.category in summary))
        summary[dataEntryOrRecord.category] = {};
      summary[dataEntryOrRecord.category] = {
        co2e: (summary[dataEntryOrRecord.category]!.co2e ?? 0) + v,
      };
    } else if (isDataEntry(dataEntryOrRecord)) {
      if (
        !Array.isArray(dataEntryOrRecord) &&
        "_usesQuestionId" in dataEntryOrRecord.data &&
        dataEntryOrRecord.data._usesQuestionId in state.answers
      ) {
        const answer =
          state.answers[dataEntryOrRecord.data._usesQuestionId].value;
        let value: number = 0;
        switch (typeof answer) {
          case "string":
            value = 0;
            break;
          case "number":
            value = answer;
            break;
          case "object":
            for (let _k in answer) {
              const _v = answer[_k];
              if (typeof _v === "number") {
                value += _v;
                break;
              }
            }
            break;
          default:
            value = 0;
        }
        v =
          dataEntryOrRecord.data.factor *
          ("value" in dataEntryOrRecord.data
            ? dataEntryOrRecord.data.value
            : 1) *
          value;
      } else if ("value" in dataEntryOrRecord) {
        v = dataEntryOrRecord.value;
      } else {
        v = 0;
      }
      if (!(dataEntryOrRecord.category in summary))
        summary[dataEntryOrRecord.category] = {};
      summary[dataEntryOrRecord.category] = {
        co2e: (summary[dataEntryOrRecord.category]!.co2e ?? 0) + v,
      };
    } else {
      v = Object.values(dataEntryOrRecord).reduce(reduceFunction, 0);
    }
    return a + v;
  };

  state.summary = summary;
  if (state.session)
    state.session.yearlyCo2e = Object.values(state.data).reduce(
      reduceFunction,
      0
    );
}

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import localFont from "@next/font/local";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { setDefaultOptions } from "date-fns";
import { de } from "date-fns/locale";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { IntlErrorCode, NextIntlProvider } from "next-intl";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ComponentProps, useCallback, useState } from "react";
import ReactModal from "react-modal";
import { Provider } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { PersistGate } from "redux-persist/integration/react";
import favIcon from "../public/favicon.png";
import Bold from "../src/components/intl/Bold";
import Green from "../src/components/intl/Green";
import SmallText from "../src/components/intl/SmallText";
import Layout from "../src/components/Layout/Layout";
import AuthContext, { AuthContextState } from "../src/context/AuthContext";
import { persistor, store } from "../src/redux/store";
import { AppTitle } from "../src/util";
import "../styles/globals.css";
import { HGFPage } from "../types/util";

setDefaultOptions({
  locale: de,
});

const Manrope = localFont({
  src: "./Manrope-VariableFont_wght.ttf",
  fallback: [
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
  ],
});

const getMessageFallback: ComponentProps<
  typeof NextIntlProvider
>["getMessageFallback"] = ({ namespace, key, error }) => {
  const path = [namespace, key].filter((part) => part != null).join(".");

  if (error.code === IntlErrorCode.MISSING_MESSAGE) {
    return process.env.NODE_ENV === "development"
      ? `${path} is not yet translated`
      : path;
  } else {
    return `Dear developer, please fix this message: ${path}`;
  }
};

type MyAppProps = Omit<AppProps, "Component"> & {
  pageProps: {
    messages: IntlMessages;
    session: Session | null | undefined;
  };
  Component: HGFPage;
};
ReactModal.setAppElement("#__next");

config.autoAddCss = false;

const AdminPathRegex = /\/admin\/?/;

export default function MyApp({ Component, pageProps }: MyAppProps) {
  const router = useRouter();
  const [c, setC] = useState<AuthContextState["c"]>({
    logout: "/auth/login",
    login: "/dashboard",
  });

  const updateC = useCallback<AuthContextState["updateCallback"]>(
    (key, value) => {
      setC((c) => ({ ...c, [key]: value }));
    },
    []
  );

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        <meta
          name="description"
          content="We are convinced that a future worth living requires effective climate protection today. Together with you, we are saving the climate."
        />
        <title>{AppTitle({})}</title>
        <link rel="shortcut icon" href={favIcon.src} type="image/x-icon" />
        <meta name="theme-color" content="#e0f1f5" />
      </Head>
      <style jsx global>{`
        html {
          font-family: ${Manrope.style.fontFamily};
        }
      `}</style>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <SessionProvider session={pageProps.session}>
            <AuthContext.Provider
              value={{
                c: c,
                updateCallback: updateC,
              }}
            >
              <NextIntlProvider
                getMessageFallback={getMessageFallback}
                timeZone="Europe/Berlin"
                messages={pageProps.messages}
                defaultTranslationValues={{
                  green: Green,
                  small: SmallText,
                  bold: Bold,
                }}
              >
                <Layout
                  a={AdminPathRegex.test(router.pathname)}
                  config={Component.Layout}
                >
                  <Component {...pageProps} />
                  <ToastContainer
                    position={toast.POSITION.BOTTOM_CENTER}
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </Layout>
              </NextIntlProvider>
            </AuthContext.Provider>
          </SessionProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

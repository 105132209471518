import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import styles from "./Button.module.scss";

export type ButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "color"
> & {
  color?: ButtonColor;
  loading?: boolean;
};

export type ButtonColor = "green" | "red" | "white";

function Button({
  className,
  color = "white",
  loading,
  type = "button",
  ...props
}: ButtonProps) {
  return (
    <button
      className={clsx(
        className,
        styles.base,
        color && styles[color],
        loading && styles.loading
      )}
      type={type}
      disabled={loading}
      {...props}
    />
  );
}

export default Button;

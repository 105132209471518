import { QueryStatus } from "@reduxjs/toolkit/query";
import { useSession } from "next-auth/react";
import { useGetCurrentUserQuery } from "../redux/apiSlice";

export default function useSessionUser() {
  const { data, status: sessionStatus } = useSession();
  const {
    data: userData,
    status: userQueryStatus,
    isLoading: isUserLoading,
    refetch,
  } = useGetCurrentUserQuery();

  return {
    userData: userData,
    sessionData: data,
    sessionStatus,
    isLoading: userQueryStatus === QueryStatus.uninitialized || isUserLoading,
    refetch,
  };
}

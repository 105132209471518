import { faRotate } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useRouter } from "next/router";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import pkg from "../../../package.json";
import { LayoutConfig } from "../../../types/util";
import LayoutContext, { LayoutContextState } from "../../context/LayoutContext";
import Button from "../Button/Button";
import Header from "../Header/Header";
import styles from "./Layout.module.scss";

export type LayoutProps = PropsWithChildren<{
  a?: boolean;
  config?: LayoutConfig;
}>;

function Layout({ a, children, config }: LayoutProps) {
  const [layoutConfig, setLayoutConfig] = useState(config);
  const router = useRouter();

  useEffect(() => {
    setLayoutConfig(config);
  }, [config]);

  const updateLayoutConfig: LayoutContextState["update"] = useCallback(
    (key, value) => {
      setLayoutConfig((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    []
  );

  return (
    <LayoutContext.Provider
      value={{ config: layoutConfig, update: updateLayoutConfig }}
    >
      <div className={clsx(styles.container, layoutConfig?.containerClassName)}>
        {process.env.NODE_ENV === "development" && (
          <Button
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              margin: 0,
              width: "auto",
            }}
            onClick={() =>
              router.push({
                pathname: router.pathname,
                query: router.query,
              })
            }
          >
            <FontAwesomeIcon icon={faRotate} />
          </Button>
        )}
        {!layoutConfig?.hideHeader && (
          <Header
            ignoreSession={layoutConfig?.ignoreSession}
            hideNavigation={layoutConfig?.hideNavigation}
          />
        )}
        <main
          className={clsx(
            styles.main,
            a && styles.admin,
            layoutConfig?.mainClassName
          )}
        >
          {children}
        </main>
        {layoutConfig?.hideFooter ? null : (
          <footer className={styles.footer}>
            ©&nbsp;{new Date().getFullYear()} Green Aureus GmbH | Alle Rechte
            vorbehalten
            {(process.env.NODE_ENV !== "production" || a) && (
              <span className={styles.version}>v{pkg.version}</span>
            )}
          </footer>
        )}
      </div>
    </LayoutContext.Provider>
  );
}

Layout.Messages = ["components.Layout", ...Header.Messages];

export default Layout;
